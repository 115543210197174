<!--
  一般机构账号
  + 修改表单
  + 禁用账号
 -->
<template>
  <div class="detail page_min_width">
    <detail-header title="集成商认证审核" />
    <div class="detail_main page_width" v-loading="loading">
      <div class="main_content">
        <info-header title="集成商认证信息" />
        <div style="padding: 20px 0;">
          <!-- 挂靠用户申请 -->
          <template v-if="formData.organizationRole === globalVar.ORG_ROLE_DEPEND">
            <info-item
              :label-width="labelWidth"
              label="姓名"
              :value="formData.username">
            </info-item>
            <info-item
              :label-width="labelWidth"
              label="最高学历"
              :value="formData.highestEducation">
            </info-item>
            <info-item
              :label-width="labelWidth"
              label="毕业院校"
              :value="formData.university">
            </info-item>
            <info-item
              :label-width="labelWidth"
              label="学历证书编号"
              :value="formData.certificateNumber">
            </info-item>
            <info-item
              :label-width="labelWidth"
              label="社会职务"
              :value="formData.socialPosition">
            </info-item>
            <info-item
              :label-width="labelWidth"
              label="从事所选科技服务年限"
              :value="formData.workingYears">
            </info-item>
            <info-item
              :label-width="labelWidth"
              label="资格证书发证时间"
              :value="formData.issuingAt">
            </info-item>
            <info-item
              :label-width="labelWidth"
              label="资格证书图片或扫描件"
              value-type="image-list"
              :value="formData.certificateAttachment ? formData.certificateAttachment.split(',') : []">
            </info-item>
            <info-item
              :label-width="labelWidth"
              label="主持或参与的项目"
              :value="formData.projectCases">
            </info-item>
            <info-item
              :label-width="labelWidth"
              label="论文、研究（调研）报告及其他成果"
              :value="formData.achievementCases">
            </info-item>
            <info-item
              :label-width="labelWidth"
              label="获奖情况"
              :value="formData.awardCases">
            </info-item>
            <info-item
              :label-width="labelWidth"
              label="技术服务情况"
              :value="formData.situationDescription">
            </info-item>
            <info-item
              :label-width="labelWidth"
              label="相关凭证"
              value-type="file-list"
              :value="formData.relatedDocuments ? JSON.parse(formData.relatedDocuments) : []">
            </info-item>
            <info-item
              :label-width="labelWidth"
              label="服务对象代表"
              :value="formData.serviceObjectRepresentative">
            </info-item>
          </template>
          <!-- 服务用户申请 -->
          <template v-if="formData.organizationRole === globalVar.ORG_ROLE_CORPORATE">
            <info-item
              :label-width="labelWidth"
              label="机构名称"
              :value="formData.organization.name">
            </info-item>
            <info-item
              :label-width="labelWidth"
              label="从事所选科技服务年限"
              :value="formData.organization.workingYears">
            </info-item>
            <info-item
              :label-width="labelWidth"
              label="技术转移示范机构"
              :value="formData.organization.technologyTransfer">
            </info-item>
            <info-item
              :label-width="labelWidth"
              label="专业技术转移服务平台"
              :value="formData.organization.technologyTransferPlatform">
            </info-item>
            <info-item
              :label-width="labelWidth"
              label="人员情况"
              :value="personnelSituation">
            </info-item>
            <info-item
              :label-width="labelWidth"
              label="机构技术经纪人数量"
              :value="brokersNumber">
            </info-item>
            <info-item
              :label-width="labelWidth"
              label="是否有科技服务章程"
              :value="formData.organization.serviceCharter">
            </info-item>
            <info-item
              :label-width="labelWidth"
              label="是否有客户管理服务规范"
              :value="formData.organization.customerManagementStandard">
            </info-item>
            <info-item
              :label-width="labelWidth"
              label="是否开展国际技术转移合作"
              :value="formData.organization.technologyTransferCooperation">
            </info-item>
            <info-item
              :label-width="labelWidth"
              label="服务对象群体"
              :value="formData.organization.targetGroups">
            </info-item>
            <info-item
              :label-width="labelWidth"
              label="经济社会效益"
              :value="formData.organization.socialResults">
            </info-item>
            <info-item
              :label-width="labelWidth"
              label="社会信誉"
              :value="formData.organization.socialReputation">
            </info-item>
            <info-item
              :label-width="labelWidth"
              label="典型服务案例"
              :value="formData.organization.serviceCase">
            </info-item>
            <info-item
              :label-width="labelWidth"
              label="经营理念"
              :value="formData.organization.managementIdea">
            </info-item>
            <info-item
              :label-width="labelWidth"
              label="经营条件"
              :value="formData.organization.operatingConditions">
            </info-item>
            <info-item
              :label-width="labelWidth"
              label="上年度技术服务情况"
              :value="formData.organization.lastYearTechnicalServices">
            </info-item>
            <info-item
              :label-width="labelWidth"
              label="上年度财务收入情况"
              :value="formData.organization.lastYearFinancialIncome">
            </info-item>
            <info-item
              :label-width="labelWidth"
              label="附件"
              value-type="file-list"
              :value="formData.organization.attachmentDocument ? JSON.parse(formData.organization.attachmentDocument) : []">
            </info-item>
          </template>
        </div>
        <div
          v-if="formData.certificationStatus === globalVar.CERTIFICATION_STATUS_PENDING"
          :style="{
            paddingLeft: labelWidth,
            marginTop: '20px'
          }">
          <el-button
            type="primary"
            size="small"
            :loading="btnLoading"
            @click="onAudit(globalVar.CERTIFICATION_STATUS_PASS)">
            同意申请
          </el-button>
          <el-button
            type="danger"
            size="small"
            plain
            :loading="btnLoading"
            @click="onAudit(globalVar.CERTIFICATION_STATUS_REJECT)">
            驳回申请
          </el-button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import * as api from '@/api'
import globalVar from '@/configs/globalVar'
export default {
  data () {
    return {
      loading: false,
      labelWidth: '180px',
      formData: {},
      btnLoading: false
    }
  },
  computed: {
    id () {
      return this.$route.params.id
    },
    // 人员情况
    personnelSituation () {
      if (this.formData.organization.personnelSituation) {
        const nums = this.formData.organization.personnelSituation.split(',')
        return `总人数：${nums[0]}人，专职从事技术转移人员：${nums[1]}人，硕士（含以上）：${nums[2]}人，大学本科：${nums[3]}人，大专及（以下）：${nums[4]}人，高级职称：${nums[5]}人，中级职称：${nums[6]}人`
      } else {
        return ''
      }
    },
    // 机构技术经纪人数量
    brokersNumber () {
      if (this.formData.organization.brokersNumber) {
        const nums = this.formData.organization.brokersNumber.split(',')
        return `高级：${nums[0]}人，中级：${nums[1]}人，初级：${nums[2]}人`
      } else {
        return ''
      }
    }
  },
  created () {
    this.getAuditDetail()
  },
  methods: {
    // 获取账号详情
    getAuditDetail () {
      this.loading = true
      api.getUserItemInfo(this.id).then(res => {
        if (res.data.code === 0) {
          this.formData = res.data.data
        } else {
          this.$message.error(res.data.message)
        }
      }).catch(err => {
        console.log(err)
        this.$message.error('请求出错')
      }).finally(() => {
        this.loading = false
      })
    },
    onAudit (logicStatus) {
      // 如果是已拒绝 则需要填写拒绝理由
      if (logicStatus === globalVar.CERTIFICATION_STATUS_REJECT) {
        this.$prompt('请输入驳回原因', '提示', {
          confirmButtonText: '驳回',
          cancelButtonText: '取消',
          inputPattern: /\S/,
          inputErrorMessage: '请输入驳回原因',
          inputPlaceholder: '请输入驳回原因'
        }).then(({ value }) => {
          this.auditConfirm('', '', logicStatus, value)
        }).catch(() => {
        })
      } else {
        this.$msgbox({
          title: '确认',
          message: '请确认你的操作',
          showCancelButton: true,
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning',
          beforeClose: (action, instance, done) => {
            if (action === 'confirm') {
              this.auditConfirm(instance, done, logicStatus)
            } else {
              done()
            }
          }
        })
      }
    },
    /**
     * 状态(审核)， 2 - 已通过, 3 - 已驳回
     */
    auditConfirm (instance, done, logicStatus, reason) {
      if (instance) {
        instance.confirmButtonLoading = true
      }
      this.btnLoading = true
      api.auditJcsDetail(this.id, {
        logicStatus,
        reason
      }).then(res => {
        if (res.data.code === 0) {
          this.$message.success('操作成功')
          this.getAuditDetail()
        } else {
          this.$message.error(res.data.message)
        }
        done && done()
      }).catch(err => {
        console.log(err)
        this.$message.error('请求出错')
      }).finally(() => {
        this.btnLoading = false
        if (instance) {
          instance.confirmButtonLoading = false
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.detail {
  .detail_main {
    padding: 30px 0;
    .main_content {
      width: 560px;
      margin: 0 auto;
    }
  }
}
</style>
